import React, { useEffect, useState, createContext } from "react";
import { useMeQuery } from "src/generated/graphql";

type GraphQLContext = {
  judge_id?: string,
  hidden?: boolean
}

export const GraphQLContext = createContext<GraphQLContext>({ hidden: false, judge_id: null });

export const CurrentUser = ({ children }: React.PropsWithChildren<{}>) => {

  const [currentUser, setCurrentUser] = useState<GraphQLContext>({ hidden: false, judge_id: null });

  const { data, loading } = useMeQuery()

  useEffect(() => {

    if (data?.me?.role == 'admin') {
      setCurrentUser({ hidden: true, judge_id: null })
    }

    if (data?.me?.role == 'judge') {
      setCurrentUser({ hidden: false, judge_id: data?.me?.id })
    }

  }, [data])

  return (
    <GraphQLContext.Provider value={{ hidden: currentUser.hidden, judge_id: currentUser.judge_id }}>
      {children}
    </GraphQLContext.Provider>
  );
}