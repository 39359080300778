import React from "react";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../lib/apolloClient";
import Head from "next/head";
import "../styles/globals.css";
import "../styles/tailwind.css";
import Bugsnag from "@bugsnag/js";
import Error from "./_error";
import { CurrentUser } from "src/lib/currentUser";

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

function MyApp({ Component, pageProps }) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <ApolloProvider client={apolloClient}>
        <CurrentUser>
          <Head>
            <title>Judge Dashboard</title>
          </Head>
          <Component {...pageProps} />
        </CurrentUser>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
